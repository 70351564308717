import { AlertColor } from '@mui/material'
import React, { ReactNode, createContext, useCallback, useState } from 'react'
import Alert from '../components/Alert'

interface AlertContextProps {
  showAlert: (message: string, severity: AlertColor | undefined) => void
}

interface AlertProps {
  open?: boolean
  message: string
  severity: AlertColor | undefined
}

export const AlertContext = createContext<AlertContextProps>(
  {} as AlertContextProps,
)

interface AlertProviderProps {
  children: ReactNode
}

const AlertInitialState: AlertProps = {
  open: true,
  message: '',
  severity: 'info',
}

export default function AlertProvider({ children }: AlertProviderProps) {
  const [alert, setAlert] = useState<AlertProps>(AlertInitialState)

  const showAlert = useCallback(
    (message: string, severity: AlertColor | undefined) => {
      setAlert({ message, severity })
    },
    [],
  )

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      {alert.message && (
        <Alert
          open
          severity={alert.severity}
          message={alert.message}
          onClose={() =>
            setAlert({
              open: false,
              message: '',
              severity: 'info',
            })
          }
        />
      )}
    </AlertContext.Provider>
  )
}
